import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService, Language, MedrecordResponse } from '@medrecord/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Role } from '../models/enums';

@Injectable()
export class SignUpService {
  constructor(private http: HttpClient, private envService: EnvService) {}

  signup(email: string, password: string, languageCode: Language, roles: Role[], redirectUri: string): Observable<any> {
    return this.http.post(`${this.envService.auth}/signup/password`, {
      email,
      password,
      languageCode,
      roles,
      redirectUri
    });
  }

  signupViaGoogle(idToken: string, languageCode: Language, roles: Role[]): Observable<MedrecordResponse> {
    return this.http.post<MedrecordResponse>(`${this.envService.auth}/signup/googletoken`, {
      idToken,
      roles,
      languageCode,
    });
  }

  confirmEmail(token: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('token', token);
    return this.http.get(this.envService.auth + '/email/confirm', { params });
  }

  signUpByAdminInvite(password: string, languageCode: string, token): Observable<any> {
    let params = new HttpParams();
    params = params.set('token', token);
    params = params.set('lang', languageCode);
    return this.http.post(this.envService.auth + '/register', { password, confirmPassword: password }, { params });
  }

  resendVerificationEmail(email: string, languageCode: string, redirectUri: string): Observable<any> {
    return this.http.post(this.envService.auth + '/resend', { email, languageCode, redirectUri });
  }
}
