import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService, StorageKeys } from '@medrecord/core';
import { AUTH_ROUTE_NAMES, AuthRouteNames } from '@medrecord/routes-auth';
import { StorageService } from '@medrecord/tools-storage';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, delay, switchMap, withLatestFrom } from 'rxjs/operators';
import { AuthRouteParams, VerificationStatus } from '../../models/enums';
import { AuthStorageItem } from '../../models/interfaces';
import { TokenGetAuthResponse } from '../../models/interfaces/token/token-getauth-response.interface';
import { AuthRedirectService } from '../../services/auth-redirect.service';
import { MedrecordOAuthService } from '../../services/medrecord-oauth.service';
import { TokenService } from '../../services/token.service';
import {
  checkPhoneCodeSuccess,
  completeSignIn,
  logoutFinished,
  navigateToHealthTalkAction,
  navigateToHealthTalkFailureAction,
  navigateToLoginPage,
  navigateToPhoneSetUpPage,
  navigateToRestorePasswordPage,
  navigateToRestorePasswordSuccessPage,
  navigateToSavePhonePage,
  navigateToSignInPage,
  navigateToTwofaSetup,
  navigateToVerifyPhonePage,
  requestTokensAction,
  savePhoneSuccess,
  signInFinishedRedirectAction,
  signInSuccessAction,
  signInViaGoogleCompleteSuccess,
  navigateToTwofaVerification,
  navigateToTwofaReconfig,
  navigateToTwofaRecovery,
  confirmEmailSuccess
} from '../auth-manager.actions';
import { getCodeVerifier, selectRedirectUri } from '../auth-manager.selectors';
import {
  twofaVerificationSuccess,
} from '../actions/twofa.actions';

@Injectable()
export class RedirectEffects {
  @Effect()
  openSignInPage$ = this.actions$.pipe(
    ofType(navigateToSignInPage, logoutFinished),
    switchMap(() => {
      const auth = this.storage.getItem<AuthStorageItem>(StorageKeys.Auth);

      if (!auth.rememberMe) {
        this.storage.extendItem(StorageKeys.Auth, { session: undefined });
      }

      this.router.navigate([this.authRouteNames.Entry]);

      return [];
    })
  );

  @Effect()
  openRestorePasswordPage$ = this.actions$.pipe(
    ofType(navigateToRestorePasswordPage),
    withLatestFrom(this.store.select(getCodeVerifier)),
    switchMap(([, codeVerifier]) => {
      return from(this.redirectService.navigateToRestorePassword()).pipe(
        switchMap((url) => this.medrecordOAuthService.loginWithMedrecord(codeVerifier, url))
      );
    })
  );

  @Effect()
  openRedirectPage$ = this.actions$.pipe(
    ofType(completeSignIn, checkPhoneCodeSuccess, savePhoneSuccess, twofaVerificationSuccess),
    withLatestFrom(this.store.select(selectRedirectUri)),
    switchMap(([, redirectUri]) => {
      const url = new URL(redirectUri);

      if (!this.storage.getItem(StorageKeys.isOAuth, false)) {
        return [requestTokensAction({ code: url.searchParams.get(AuthRouteParams.Code) })];
      }

      try {
        opener.location.replace(redirectUri);
        close();
      } catch {
        location.replace(redirectUri);
      }

      return [];
    })
  );

  @Effect()
  verificationRedirectAction$ = this.actions$.pipe(
    ofType(signInSuccessAction, signInViaGoogleCompleteSuccess),
    switchMap(({ session, twoFactorStatus, redirectUri }) => {
      const twoFactorOptions = {
        [VerificationStatus.Disabled]: [completeSignIn({ session, twoFactorStatus, redirectUri })],

        [VerificationStatus.Passed]: [completeSignIn({ session, twoFactorStatus, redirectUri })],

        [VerificationStatus.PhoneSetupRequired]: [navigateToPhoneSetUpPage()],

        [VerificationStatus.PhoneVerificationRequired]: [navigateToVerifyPhonePage()],

        [VerificationStatus.TotpSetupRequired]: [navigateToTwofaSetup()],

        [VerificationStatus.TotpVerificationRequired]: [navigateToTwofaVerification()],
      };
      return twoFactorOptions[twoFactorStatus];
    })
  );

  @Effect()
  navigateToRestorePasswordSuccessPage$ = this.actions$.pipe(
    ofType(navigateToRestorePasswordSuccessPage),
    switchMap(() => {
      this.router.navigate(
        [
          this.authRouteNames.Password.Forgot,
          this.authRouteNames.Password.Restore,
          this.authRouteNames.Password.Success,
        ],
        { queryParamsHandling: 'merge' }
      );

      return [];
    })
  );

  @Effect()
  navigateToPhoneSetUpPage$ = this.actions$.pipe(
    ofType(navigateToPhoneSetUpPage),
    switchMap(() => {
      this.router.navigate(
        [
          this.authRouteNames.Entry,
          this.authRouteNames.Verification.Entry,
          this.authRouteNames.Verification.Phone,
          this.authRouteNames.Verification.Init,
        ],
        { queryParamsHandling: 'merge' }
      );

      return [];
    })
  );

  @Effect()
  navigateToSavePhonePage$ = this.actions$.pipe(
    ofType(navigateToSavePhonePage),
    switchMap(() => {
      this.router.navigate(
        [
          this.authRouteNames.Entry,
          this.authRouteNames.Verification.Entry,
          this.authRouteNames.Verification.Phone,
          this.authRouteNames.Verification.Save,
        ],
        {
          queryParamsHandling: 'merge',
        }
      );

      return [];
    })
  );

  @Effect()
  navigateToVerifyPhonePage$ = this.actions$.pipe(
    ofType(navigateToVerifyPhonePage),
    switchMap(() => {
      this.router.navigate(
        [
          this.authRouteNames.Entry,
          this.authRouteNames.Verification.Entry,
          this.authRouteNames.Verification.Phone,
          this.authRouteNames.Verification.Code,
        ],
        { queryParamsHandling: 'merge' }
      );

      return [];
    })
  );
  @Effect()
  navigateToTotpSetup$ = this.actions$.pipe(
    ofType(navigateToTwofaSetup),
    switchMap(() => {
      this.router.navigate(
        [
          this.authRouteNames.Entry,
          this.authRouteNames.Twofa.Entry,
          this.authRouteNames.Twofa.Setup,
        ],
        { queryParamsHandling: 'merge' }
      );

      return [];
    })
  );

  @Effect()
  navigateToTotpVerification$ = this.actions$.pipe(
    ofType(navigateToTwofaVerification),
    switchMap(() => {
      this.router.navigate(
        [
          this.authRouteNames.Entry,
          this.authRouteNames.Twofa.Entry,
          this.authRouteNames.Twofa.Verification,
        ],
        { queryParamsHandling: 'merge' }
      );

      return [];
    })
  );

  @Effect()
  navigateToTwofaReconfig$ = this.actions$.pipe(
    ofType(navigateToTwofaReconfig),
    switchMap(() => {
      this.router.navigate(
        [
          this.authRouteNames.Entry,
          this.authRouteNames.Twofa.Entry,
          this.authRouteNames.Twofa.Reconfiguration,
        ],
        { queryParamsHandling: 'merge' }
      );

      return [];
    })
  );

  @Effect()
  navigateToTwofaRecovery$ = this.actions$.pipe(
    ofType(navigateToTwofaRecovery),
    switchMap(() => {
      this.router.navigate(
        [
          this.authRouteNames.Entry,
          this.authRouteNames.Twofa.Entry,
          this.authRouteNames.Twofa.Recovery,
        ],
        { queryParamsHandling: 'merge' }
      );

      return [];
    })
  );

  @Effect()
  navigateToLoginPage$ = this.actions$.pipe(
    ofType(navigateToLoginPage),
    switchMap(() => {
      this.router.navigate(['/'], { queryParamsHandling: 'merge' });
      return [];
    })
  );

  @Effect()
  signInFinished$ = this.actions$.pipe(
    ofType(signInFinishedRedirectAction),
    switchMap(({ path, queryParams }) => {
      this.router.navigate(path ? path : ['/'], { queryParams });

      return [];
    })
  );

  @Effect()
  navigateToHealthTalk$ = this.actions$.pipe(
    ofType(navigateToHealthTalkAction),
    switchMap(() =>
      this.tokenService.getAuthToken().pipe(
        switchMap((response: TokenGetAuthResponse) => {
          const url = `${this.envService.healthTalkGui}?temp_token=${response.token}`;
          window.open(url, '_blank');
          return of();
        }),
        catchError(({ error }) => [navigateToHealthTalkFailureAction({ error })])
      )
    )
  );

  confirmEmailSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmEmailSuccess),
      delay(1000),
      switchMap(() => {
        const redirectUri = new URLSearchParams(window.location.search).get('redirect_uri');
        if (redirectUri) {
          window.location.href = redirectUri;
          return [];
        }
        return [navigateToSignInPage()];
      })
    )
  );

  constructor(
    private redirectService: AuthRedirectService,
    private tokenService: TokenService,
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private storage: StorageService<StorageKeys>,
    private medrecordOAuthService: MedrecordOAuthService,
    private envService: EnvService,

    @Inject(AUTH_ROUTE_NAMES) private authRouteNames: AuthRouteNames
  ) {}
}
