import { Component, Inject, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from '@medrecord/services-form-validation';
import { TranslateService } from '@ngx-translate/core';
import {
  clearError,
  FORM_RULES,
  FormRules,
  getErrorCode,
  signUpAction,
  AuthStorageItem,
  confirmedPrivacyPolicyAction,
} from '@medrecord/managers-auth';
import { AUTH_ROUTE_NAMES, AuthRouteNames } from '@medrecord/routes-auth';
import { Store } from '@ngrx/store';
import { MapFieldOptions, MapFieldType, PrivacyPolicyDialogComponent } from '@medrecord/ui';
import { navigateAction } from '@medrecord/tools-navigation';
import { EnvService, ErrorCode, StorageKeys } from '@medrecord/core';
import { StorageService } from '@medrecord/tools-storage';

@Component({
  selector: 'medrecord-auth-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss'],
})
export class SignupFormComponent {
  @Input()
  form: FormGroup;
  @ViewChild('privacyPolicy') privacyPolicy: PrivacyPolicyDialogComponent;

  showPassword = false;
  showRepeatPassword = false;

  errorCode$ = this.store.select(getErrorCode);

  readonly mapFieldType = MapFieldType;
  readonly isOAuth = this.storage.getItem<boolean>(StorageKeys.isOAuth, false);
  readonly googleAuthDisabled = this.envService.googleAuthDisabled;

  readonly emailOptions: MapFieldOptions = {
    relatedErrors: [ErrorCode.EmailInUse],
    onChangeCb: () => {
      this.store.dispatch(clearError());
    },
  };

  readonly confirmOptions: MapFieldOptions = {
    onChangeCb: () => {
      this.store.dispatch(confirmedPrivacyPolicyAction({ confirmed: !!this.form.value.confirm }));
    },
  };

  constructor(
    fb: FormBuilder,
    validationService: ValidationService,
    translateService: TranslateService,

    private store: Store,
    private storage: StorageService<StorageKeys>,
    @Inject(FORM_RULES) formRules: FormRules,
    @Inject(AUTH_ROUTE_NAMES) private authRouteNames: AuthRouteNames,
    private envService: EnvService
  ) {
    const auth = this.storage.getItem<AuthStorageItem>(StorageKeys.Auth);
    this.form = fb.group(
      {
        email: [
          null,
          [
            validationService.required(translateService.instant('auth_validation_error_required')),
            validationService.email(translateService.instant('auth_validation_error_email')),
          ],
        ],

        password: [
          null,
          [
            validationService.required(translateService.instant('auth_validation_error_required')),
            validationService.maxLength(
              formRules.passwordMaxLength,
              translateService.instant('auth_validation_error_required')
            ),
            validationService.passwordComplexity(translateService.instant('auth_validation_password_complexity')),
          ],
        ],

        repeatPassword: [
          null,
          [validationService.required(translateService.instant('auth_validation_error_required'))],
        ],

        confirm: [
          auth?.confirmedPrivacyPolicy,
          [validationService.requiredTrue(translateService.instant('auth_validation_error_required'))],
        ],
      },
      {
        validators: validationService.shouldBeEqual(
          'password',
          'repeatPassword',
          translateService.instant('auth_validation_password_not_equal')
        ),
      }
    );
  }

  handleFormSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.store.dispatch(
        signUpAction({
          email: this.form.value.email,
          password: this.form.value.password,
          redirectUri: this.getAppUri(),
          successActions: [
            clearError(),
            navigateAction({ path: [this.authRouteNames.Entry, this.authRouteNames.Auth.FinishedSignUp] }),
          ],
        })
      );
    }
  }

  getAppUri(): string {
    const { protocol, host } = window.location;
    return `${protocol}//${host}`;
  }

  openTerms(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.privacyPolicy.openDialog();
  }

  goToLogin(): void {
    this.store.dispatch(
      navigateAction({
        path:
          !this.isOAuth && this.googleAuthDisabled
            ? [this.authRouteNames.Entry, this.authRouteNames.Auth.Login, this.authRouteNames.Auth.Credentials]
            : [this.authRouteNames.Entry, this.authRouteNames.Auth.Login],
        extras: { queryParamsHandling: 'merge' },
      })
    );
  }
}
